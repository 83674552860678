import axios from "axios";
import { getRepositoryId } from "./Repository";
import { importProject } from "./Git";
import { sendMail } from "./Mail";
import { runProgress } from "../Progress";
import { getRepository } from "./Repository";
import { createBuild } from "./Build";
import { appendDeploymentPool, getDeploymentGroup, getAgentGroup } from "./Deployment";
import { createRelease } from "./Release";
import { createBranch, createBranchForProject } from "./Repository";
import { createCommit } from "./Repository";
import { createDatabase } from "./Database";
import { runBuild } from "./Build";

async function createProjectInDevOps(accessToken, project) {
	const headers = {
		"Content-Type": "application/json",
		Accept: "application/json",
		BearerToken: accessToken
	};
	return await axios.post(
		`${process.env.REACT_APP_WEB_API}/projects/create`,
		{
			projectName: project.project.name,
			projectDescription: project.project.description,
			processTemplateId: `${process.env.REACT_APP_PROCESS_TEMPLATE}`,
			projectType: project.project.projectType,
			testServer: project.project.server,
			testServerUrl: project.project.testServerUrl
		},
		{ headers: headers }
	);
}
async function CreateDw10SiteOnTestserver(accessToken, account, project, testServer) {
	let repositoryIdResponse = await getRepositoryId(accessToken, "Boilerplates");

	let ProjectResponse = await getProject(
		accessToken,
		"Boilerplates",
		repositoryIdResponse.data,
		account
	);

	await createRelease(
		accessToken,
		project.project,
		"12601",
		ProjectResponse.data,
		"607",
		account,
		"dw10BoilerplateRelease",
		testServer
	);
	await createBranchForProject(
		accessToken,
		"227f9634-3458-4c3a-8c16-992ba9b61dc0",
		project.project
	);
}
function setStatus(done, next, progress) {
	var loading = document.querySelector(".loading." + done);
	loading.classList.remove("loading");
	loading.classList.add("success");
	loading.querySelector(".loading-icon").classList.add("success-icon");
	loading.querySelector(".loading-icon").classList.remove("loading-icon");

	runProgress(progress);

	setTimeout(() => {
		loading.classList.add("d-none");
		var loading2 = document.querySelector(".loading." + next);
		if (loading2) {
			loading2.classList.remove("d-none");
		}
	}, 1500);
}
export async function createProject(accessToken, project, account) {
	let testServer = "azure-test001";

	if (project.project.projectType === "dw10") {
		testServer = "azure-test002";
	}

	// Create new project in DewOps
	if (project.project.projectType == "dw10") {
		await CreateDw10SiteOnTestserver(accessToken, account, project, testServer);
	}

	await createProjectInDevOps(accessToken, project, account);
	// We need to wait 5 sek to ensure that the project has been created all the way.
	setTimeout(async () => {
		if (project.project.projectType !== "Custom Project with empty repository") {
			// Get git Repository ID
			let repositoryIdResponse = await getRepositoryId(accessToken, project.project.name);
			// Get  Id for Devops project

			let ProjectResponse = await getProject(
				accessToken,
				project.project.name,
				repositoryIdResponse.data,
				account
			);
			setStatus("project", "repo", 0.125);

			// Import git project from boilerplate into new Devops Project
			await importProject(
				accessToken,
				project.project,
				repositoryIdResponse.data,
				ProjectResponse.data,
				account
			);
			setStatus("repo", "build", 0.25);
			// Get id for latest commit to main branch in new repository.
			let repositoryResponse = await getRepository(
				accessToken,
				project.project,
				repositoryIdResponse.data,
				ProjectResponse.data,
				account
			);
			// Create build pipeline in new devOps project
			let createBuildResponse = await createBuild(
				accessToken,
				project.project,
				repositoryIdResponse.data
			);
			setStatus("build", "deployment", 0.375);

			setStatus("deployment", "release", 0.5);
			let queueid = 0;
			if (project.project.projectType == "dw10") {
				let getAgentPoolResponse = await getAgentGroup(accessToken, project.project.name);
				queueid = getAgentPoolResponse.data.id;
			} else {
				// Append new DevOps project to deployment pool  for test001
				await appendDeploymentPool(accessToken, project.project, testServer);
				// get Deployment Pool ID
				let getDeploymentGroupResponse = await getDeploymentGroup(
					accessToken,
					project.project.name
				);
				queueid = getDeploymentGroupResponse.data.id;
				// Create new release pipeline
			}
			let createReleaseResponse = await createRelease(
				accessToken,
				project.project,
				queueid,
				ProjectResponse.data,
				createBuildResponse.data,
				account,
				project.project.projectType,
				testServer
			);
			setStatus("release", "branch", 0.625);
			setStatus("branch", "pull-request", 0.75);
			//Todo: find out if the "if dw10" inside "createCommit" needs to be moved.
			// update Database connection in GlobalSettings, rename project file to match new project. create, approve and complete pull request to move this to release branch.
			await createCommit(
				accessToken,
				project.project,
				repositoryIdResponse.data,
				repositoryResponse.data,
				createReleaseResponse.data,
				testServer
			);
			// Create Release branch in GIT
			await createBranch(accessToken, repositoryIdResponse.data, project);
			setStatus("pull-request", "create-database", 0.875);
			// create new  dynamicweb database from BacPac file, found in Azure blob storage.
			await createDatabase(accessToken, project.project, testServer);
			// run build pipeline based on release-branch. This activates release, which deploys the site.

			await runBuild(accessToken, project.project);

			var loading = document.querySelector(".loading.create-database");
			if (loading !== null) {
				loading.classList.remove("loading");
				loading.classList.add("success");
				loading.querySelector(".loading-icon").classList.add("success-icon");
				loading.querySelector(".loading-icon").classList.remove("loading-icon");

				runProgress(1);
				setTimeout(() => {
					// remove loading overlay
					document.querySelector(".loading-overlay").remove();

					document.querySelector(".js-final-btn").click();
				}, 1500);
			}
		} else {
			// remove loading overlay
			document.querySelector(".loading-overlay").remove();
			document.querySelector(".js-final-btn").click();
		}
		// SEND MAIL
		sendMail(project.project, account);
	}, 5000);
}

export async function getProject(accessToken, projectName) {
	const headers = {
		"Content-Type": "application/json",
		Accept: "application/json",
		BearerToken: accessToken
	};

	return await axios.get(`${process.env.REACT_APP_WEB_API}/projects/${projectName}`, {
		headers: headers
	});
}
